import React from "react"

import { PageRendererProps } from "gatsby"

import { WrappedBase } from "../shared-components/Shared/WrappedBase"
import { UserContext } from "../shared-components/Shared/UserProvider"

import SignUpPage from "../views/SignUp"

import config from "../../config.json"
import { amplitude, registerButtonClick } from "../utils/amplitude"

const pageName = "verification_page"

const IndexPage: React.FunctionComponent<PageRendererProps> = ({
  location,
}) => {
  const [hasSeen, see] = React.useState(false)

  React.useEffect(() => {
    if (!hasSeen) {
      window.addEventListener("beforeunload", () => {
        amplitude.logEvent("bounced_verification")
      })

      see(true)
    }
  }, [hasSeen])

  return (
    <WrappedBase
      metaTitle="Habito | Verification"
      metaDescription="Verify your account. Habito is the UK's free online mortgage broker, making mortgage worries history. Our smart technology searches the whole market - thousands of mortgage products from over 90 lenders - to get you the perfect mortgage."
      canonicalUrl="https://www.habito.com/verify"
      noIndex={true} // signup page should never be indexed
      pageName={pageName}
      intercom={true}
      config={config}
      head={null}
    >
      <UserContext.Consumer>
        {user => (
          <SignUpPage
            isLoggedIn={Boolean(user)}
            location={location}
            showOnlySuccessScreen={true}
            registerButtonClick={registerButtonClick}
            amplitudeClient={amplitude}
          />
        )}
      </UserContext.Consumer>
    </WrappedBase>
  )
}

export default IndexPage
